<template>
  <el-dialog
    title="Filter"
    :visible.sync="reportsStore.filterDialog"
    width="35%"
    top="30px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align="center"
  >
    <el-row :gutter="5" class="filter-body">
      <el-col :span="24">
        <el-form :model="filter">
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="marka" label="Marka">
                <el-input
                  clearable
                  v-model="filter.marka"
                  placeholder="marka"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="get_truck_number" label="Moshina nomeri">
                <el-input
                  clearable
                  v-model="filter.get_truck_number"
                  placeholder="Moshina nomeri"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="insurance" label="Sug'urta">
                <el-input
                  clearable
                  v-model="filter.insurance.start_date"
                  placeholder="boshlanish vaqti"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="insurance" label="Sug'urta">
                <el-input
                  clearable
                  v-model="filter.insurance.end_date"
                  placeholder="tugash vaqti"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="technical_monitoring"
                label="Texnik tekshiruv"
              >
                <el-input
                  clearable
                  v-model="filter.technical_monitoring.start_date"
                  placeholder="boshlanish vaqti"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="technical_monitoring"
                label="Texnik tekshiruv"
              >
                <el-input
                  clearable
                  v-model="filter.technical_monitoring.end_date"
                  placeholder="tugash vaqti"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="advert_permit" label="Reklama ruxsati">
                <el-input
                  clearable
                  v-model="filter.advert_permit.start_date"
                  placeholder="boshlanish vaqti"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="advert_permit" label="Reklama ruxsati">
                <el-input
                  clearable
                  v-model="filter.advert_permit.end_date"
                  placeholder="tugash vaqti"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_oil_state" label="Moshina yoqilg'isi">
                <el-input
                  clearable
                  v-model="filter.truck_oil_state.start_usage"
                  placeholder="foydalanish boshlanishi"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="truck_oil_state" label="Moshina yoqilg'isi">
                <el-input
                  clearable
                  v-model="filter.truck_tire_state.end_usage"
                  placeholder="foydalanish tugashi"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_tire_state" label="Moshina baloni">
                <el-input
                  clearable
                  v-model="filter.truck_tire_state.start_usage"
                  placeholder="foydalanish boshlanishi"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="truck_tire_state" label="Moshina baloni">
                <el-input
                  clearable
                  v-model="filter.get_truck_number.end_usage"
                  placeholder="foydalanish tugashi"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_accumulator_state" label="Moshina akkumlyatori">
                <el-input
                  clearable
                  v-model="filter.truck_accumulator_state.start_usage"
                  placeholder="foydalanish boshlanishi"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="truck_accumulator_state" label="Moshina akkumlyatori">
                <el-input
                  clearable
                  v-model="filter.truck_accumulator_state.end_usage"
                  placeholder="foydalanish tugashi"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="truck_kolodka_state" label="Moshina kolodkasi">
                <el-input
                  clearable
                  v-model="filter.truck_kolodka_state.start_usage"
                  placeholder="foydalanish boshlanishi"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="truck_kolodka_state" label="Moshina kolodkasi">
                <el-input
                  clearable
                  v-model="filter.truck_kolodka_state.end_usage"
                  placeholder="foydalanish tugashi"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clearAndClose()">Tozalash</el-button>
      <el-button type="primary" style="color: white" @click="search()"
        >Izlash</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      filter: {
        marka: '',
        get_truck_number: '',
        insurance: {
          start_date: '',
          end_date: ''
        },
        technical_monitoring: {
          start_date: '',
          end_date: ''
        },
        advert_permit: {
          start_date: '',
          end_date: ''
        },
        truck_oil_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_tire_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_accumulator_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_kolodka_state: {
          start_usage: '', // num
          end_usage: '' // num
        }
      }
    }
  },
  computed: {
    ...mapState(['reportsStore'])
  },
  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER', 'SET_QUERY_PARAM']),
    search() {
      this.SET_FILTER({ filter: this.filter, num: '4' })
      this.FILTER_DIALOG_TOGGLE(false)
      this.SET_QUERY_PARAM({ name: 'currentPage', value: 1 })
    },
    clearAndClose() {
      this.FILTER_DIALOG_TOGGLE(false)
      this.filter = {
        marka: '',
        get_truck_number: '',
        insurance: {
          start_date: '',
          end_date: ''
        },
        technical_monitoring: {
          start_date: '',
          end_date: ''
        },
        advert_permit: {
          start_date: '',
          end_date: ''
        },
        truck_oil_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_tire_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_accumulator_state: {
          start_usage: '', // num
          end_usage: '' // num
        },
        truck_kolodka_state: {
          start_usage: '', // num
          end_usage: '' // num
        }
      }
      this.SET_FILTER({ filter: this.filter, num: '4' })
    }
  }
}
</script>

<style>
.filter-body {
  /* border: 1px solid red; */
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
